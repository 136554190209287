<template>
	<div class="box">
		<div class="null-page" v-show="yes"></div>
		<div v-loading="loading">
			<!-- 退款详情start -->
			<div>
				<el-card class="box-card order-list">
					<div slot="header" class="clearfix">
						<el-breadcrumb separator="/">
							<el-breadcrumb-item :to="{ path: '/member/activist' }">订单售后</el-breadcrumb-item>
							<el-breadcrumb-item>售后详情</el-breadcrumb-item>
						</el-breadcrumb>
					</div>
					<div>
						<div class="block-text status_title">
							<h4>
								{{$lang('service_status_title')}}：
								<span class="ns-text-color">{{ detail.service_status_name }}</span>
							</h4>
						</div>
						<div class="status-wrap">
							<el-timeline>
								<el-timeline-item class="buyer"
									v-for="(processItem, processIndex) in detail.all_process"
									:key="processIndex"
									:timestamp="processItem.title"
									:color="getcolor(processIndex)"
									placement="top"
								>
									<div><p>{{ processItem.desc }}</p></div>
								</el-timeline-item>
							</el-timeline>
						</div>
					</div>
				</el-card>

				<!-- 退货地址 -->
				<div v-if="(detail.service_type == 2 || detail.service_type == 3) && detail.service_status >= 3">
					<div class="item-block">
						<div class="block-text return-address">退货地址：{{ detail.shop_address }}</div>
						<div class="address-wrap" v-if="detail.refund_delivery_no">
							<div class="title-text">买家退货物流信息</div>
							<div class="block-text">物流渠道：{{ detail.refund_delivery_name }}</div>
							<div class="block-text">货运单号：{{ detail.refund_delivery_no }}</div>
							<div class="block-text">物流说明：{{ detail.refund_delivery_remark }}</div>
						</div>
						<div class="address-wrap" v-if="detail.repeat_delivery_no">
							<div class="title-text">卖家重寄物流信息</div>
							<div class="block-text">物流渠道：{{ detail.repeat_delivery_name }}</div>
							<div class="block-text">货运单号：{{ detail.repeat_delivery_no }}</div>
						</div>
					</div>
				</div>

				<div v-if="detail.service_type == 4 && (detail.service_status == 10 || detail.service_status == 11 || detail.service_status == 3)">
					<div class="item-block">
						<div class="refund-repair-wrap">
							<div class="title-text">维修师傅信息</div>
							<div class="block-text">姓名：{{ detail.repairman }}</div>
							<div class="block-text">电话：{{ detail.repairman_mobile }}</div>
						</div>
					</div>
				</div>

				<!--退款详情-->
				<div class="item-block">
					<div class="goods-list">
						<table>
							<tr>
								<td width="62.5%">商品</td>
								<td width="12.5%">数量</td>
								<td width="12.5%">金额</td>
							</tr>
						</table>
					</div>
				</div>

				<div class="item-block">
					<div class="goods-list">
						<table>
							<tr>
								<td width="62.5%">
									<div class="goods-info">
										<div class="goods-info-left">
											<router-link :to="{ path: '/product-' + detail.goods_id + '.html' }" target="_blank">
												<img class="goods-img" :src="$img(detail.sku_image)" @error="detail.sku_image = defaultGoodsImage" />
											</router-link>
										</div>
										<div class="goods-info-right">
											<div class="goods-name">
												<router-link :to="{ path: '/product-' + detail.goods_id + '.html' }" target="_blank">{{ detail.sku_name }}</router-link>
											</div>
											<div class="goods-spec" v-if="detail.sku_spec_format">
												<span v-for="(x, i) in detail.sku_spec_format" :key="i">
													{{ x.spec_value_name }} {{ i < detail.sku_spec_format.length - 1 ? '; ' : '' }}
												</span>
											</div>
										</div>
									</div>
								</td>
								<td width="12.5%" class="goods-num">{{ detail.num }}</td>
								<td width="12.5%" class="goods-money">{{ siteInfo.price_unit }}{{ detail.price }}</td>
							</tr>
						</table>
					</div>
				</div>

				<div class="item-block">
					<div class="order-statistics">
						<table>
							<tr>
								<td align="right" width="15%">售后方式：</td>
								<td align="left">{{ detail.service_type_name }}</td>
							</tr>
							<tr>
								<td align="right">售后原因：</td>
								<td align="left">{{ detail.service_reason }}</td>
							</tr>
							<tr v-if="detail.service_type == 1 || detail.service_type == 2">
								<td align="right">退款金额：</td>
								<td align="left">{{ siteInfo.price_unit }}{{ detail.apply_money }}</td>
							</tr>
							<tr>
								<td align="right">售后编号：</td>
								<td align="left">{{ detail.service_no }}</td>
							</tr>
							<tr>
								<td align="right">申请时间：</td>
								<td align="left">{{ $util.timeStampTurnTime(detail.service_action_time) }}</td>
							</tr>
							<tr v-if="detail.service_time">
								<td align="right">退款时间：</td>
								<td align="left">{{ $util.timeStampTurnTime(detail.service_time) }}</td>
							</tr>
							<tr>
								<td align="right">补充描述和凭证：</td>
								<td align="left">{{ detail.service_remark }}</td>
							</tr>
							<tr>
								<td align="right"> </td>
								<td align="left">
									<div class="img-list" v-if="detail.attachment">
										<div class="file-box" v-for="(i, t) in detail.attachment" :key="t">
											<video v-if="i.file_ext == 'mp4'" ref="myVideo" :src="i.pic_path" controls="controls" class="video-box"></video>
											<el-image :src="$img(i.pic_path)" :preview-src-list="filesList" v-else></el-image>
										</div>
										

									</div>
								</td>
							</tr>
						</table>
					</div>
					<div class="clear"></div>
				</div>

				<div class="item-block" v-if="detail.refund_action.length">
					<div class="order-submit" v-for="(actionItem, actionIndex) in detail.refund_action" :key="actionIndex">
						<el-button type="primary" class="el-button--primary" @click="refundAction(actionItem.event)">{{ actionItem.title }}</el-button>
					</div>

					<div class="order-submit" v-if="false">
						<el-button type="primary" class="el-button--primary" @click="refundAction('complain')">平台维权</el-button>
					</div>

					<div class="clear"></div>
				</div>
			</div>
			<!-- 退款详情end -->

			<!-- 输入物流信息弹出 -->
			<el-dialog title="退货物流信息" :visible.sync="refundDeliveryDialog" width="50%">
				<el-form ref="form" :model="formData" label-width="80px">
					<el-form-item label="物流渠道"><el-input v-model="formData.refund_delivery_name" placeholder="请输入物流公司"></el-input></el-form-item>

					<el-form-item label="货运单号"><el-input v-model="formData.refund_delivery_no" placeholder="请输入物流单号"></el-input></el-form-item>

					<el-form-item label="物流说明"><el-input v-model="formData.refund_delivery_remark" placeholder="选填"></el-input></el-form-item>
				</el-form>
				<span slot="footer" class="dialog-footer">
					<el-button @click="refundDeliveryDialog = false">取 消</el-button>
					<el-button type="primary" @click="refurnGoods('form')">确 定</el-button>
				</span>
			</el-dialog>
		</div>
	</div>
</template>

<script>
import { refundData, refund, detail, delivery, cancleRefund, confirmReceipt, confirmRepair } from '@/api/order/service';
import { mapGetters } from 'vuex';
export default {
	name: 'service_detail',
	components: {},
	data: () => {
		return {
			orderGoodsServiceId: '',
			isSub: false,
			filesList: [],
			detail: {
				refund_action: []
			},
			formData: {
				refund_delivery_name: '',
				refund_delivery_no: '',
				refund_delivery_remark: ''
			},
			actionOpen: false, //协商记录
			refundDeliveryDialog: false, //发货地址弹出
			loading: true,
			yes: true
		};
	},
	created() {
		if (this.$route.query.order_goods_service_id) this.orderGoodsServiceId = this.$route.query.order_goods_service_id;
		this.getRefundDetail();
		if (this.$route.query.action && this.$route.query.action == 'returngoods') {
			this.refundDeliveryDialog = true;
		}
	},
	computed: {
		...mapGetters(['defaultGoodsImage', "siteInfo"])
	},
	mounted() {
		let self = this;
		setTimeout(function() {
			self.yes = false
		}, 300)
	},
	methods: {
		getcolor(index) {
			var color = (index <= this.detail.process_active) ? '#ff4544' : '#E4E7ED';
			return color;
		},
		//退款详情相关
		getRefundDetail() {
			this.loading = true;
			detail({ order_goods_service_id: this.orderGoodsServiceId })
				.then(res => {
					const { code, message, data } = res;
					if (code >= 0) {
						this.filesList = []
						for (let i = 0; i < data.attachment.length; i++) {
							if (data.attachment[i]['file_ext'] != 'mp4') {
								this.filesList.push(data.attachment[i]['pic_path'])
							}
						}
						if (data.sku_spec_format) {
							try {
								data.sku_spec_format = JSON.parse(data.sku_spec_format);
							} catch (e) {
								data.sku_spec_format = data.sku_spec_format;
							}
						} else {
							data.sku_spec_format = [];
						}
						this.detail = data;
					} else {
						this.$message({
							message: '未获取到该订单项退款信息！',
							type: 'warning',
							duration: 2000,
							onClose: () => {
								this.$router.push({ path: '/member/activist' });
							}
						});
					}
					this.loading = false;
				})
				.catch(err => {
					this.loading = false;
					this.$message.error({
						message: err.message,
						duration: 2000,
						onClose: () => {
							this.$router.push({ path: '/member/activist' });
						}
					});
				});
		},
		refundAction(event) {
			switch (event) {
				case 'orderRefundCancel':
					this.cancleRefund(this.detail.order_goods_service_id);
					break;
				case 'orderConfirmReceipt':
					this.confirmReceipt(this.detail.order_goods_service_id);
					break;
				case 'orderConfirmRepair':
					this.confirmRepairRefund(this.detail.order_goods_service_id);
					break;
				case 'orderRefundDelivery':
					this.refundDeliveryDialog = true;
					break;
				case 'orderRefundAsk':
					this.$router.push({ path: '/member/service?order_goods_service_id=' + this.detail.order_goods_service_id });
					break;
				case 'complain':
					this.$router.push({ path: '/member/complain?order_goods_id=' + this.detail.order_goods_service_id });
					break;
			}
		},
		refurnGoods() {
			if (this.formData.refund_delivery_name == '') {
				this.$message({ message: '请输入物流渠道', type: 'warning' });
				return false;
			}
			if (this.formData.refund_delivery_no == '') {
				this.$message({ message: '请输入货运单号', type: 'warning' });
				return false;
			}
			this.formData.order_goods_service_id = this.orderGoodsServiceId;
			if (this.isSub) return;
			this.isSub = true;

			delivery(this.formData)
				.then(res => {
					const { code, message, data } = res;
					if (code >= 0) {
						this.getRefundDetail();
						this.refundDeliveryDialog = false;
					} else {
						this.$message({
							message: '未获取到该订单项退款信息！',
							type: 'warning',
							duration: 2000,
							onClose: () => {
								this.$router.push({ path: '/member/activist' });
							}
						});
					}
				})
				.catch(err => {
					this.$message.error({
						message: err.message,
						duration: 2000,
						onClose: () => {
							this.$router.push({ path: '/member/activist' });
						}
					});
				});
		},
		cancleRefund(order_goods_service_id) {
			this.$confirm('撤销之后本次申请将会关闭,如后续仍有问题可再次发起申请', '提示', {
				confirmButtonText: '确认撤销',
				cancelButtonText: '暂不撤销',
				type: 'warning'
			})
				.then(() => {
					if (this.isSub) return;
					this.isSub = true;

					cancleRefund({ order_goods_service_id: order_goods_service_id })
						.then(res => {
							const { code, message, data } = res;
							if (code >= 0) {
								this.$message({
									message: '撤销成功！',
									type: 'success',
									duration: 2000,
									onClose: () => {
										this.$router.push({ path: '/member/activist' });
									}
								});
							} else {
								this.$message({ message: message, type: 'warning' });
							}
						})
						.catch(err => {
							this.$message.error({
								message: err.message,
								duration: 2000,
								onClose: () => {
									this.$router.push({ path: '/member/activist' });
								}
							});
						});
				})
				.catch(() => {});
		},
		confirmReceipt(order_goods_service_id) {
			this.$confirm('您确定已经收到货物了吗', '提示', {
				confirmButtonText: '确认',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					if (this.isSub) return;
					this.isSub = true;

					confirmReceipt({ order_goods_service_id: order_goods_service_id })
						.then(res => {
							const { code, message, data } = res;
							if (code >= 0) {
								this.$message({
									message: '操作成功！',
									type: 'success',
									duration: 2000,
									onClose: () => {
										this.$router.push({ path: '/member/activist' });
									}
								});
							} else {
								this.$message({ message: message, type: 'warning' });
							}
						})
						.catch(err => {
							this.$message.error({
								message: err.message,
								duration: 2000,
								onClose: () => {
									this.$router.push({ path: '/member/activist' });
								}
							});
						});
				})
				.catch(() => {});
		},
		confirmRepairRefund(order_goods_service_id) {
			this.$confirm('您确定已经维修好了吗', '提示', {
				confirmButtonText: '确认',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					if (this.isSub) return;
					this.isSub = true;

					confirmRepair({ order_goods_service_id: order_goods_service_id })
						.then(res => {
							const { code, message, data } = res;
							if (code >= 0) {
								this.$message({
									message: '操作成功！',
									type: 'success',
									duration: 2000,
									onClose: () => {
										this.$router.push({ path: '/member/activist' });
									}
								});
							} else {
								this.$message({ message: message, type: 'warning' });
							}
						})
						.catch(err => {
							this.$message.error({
								message: err.message,
								duration: 2000,
								onClose: () => {
									this.$router.push({ path: '/member/activist' });
								}
							});
						});
				})
				.catch(() => {});
		}
	}
};
</script>
<style scoped>
.status-wrap /deep/ .el-timeline-item__timestamp {
	color: #303133;
}
.status-wrap /deep/ .el-timeline-item__content {
	color: #909399;
}
</style>
<style lang="scss" scoped>
.box {
	width: 100%;
	position: relative;
}

.null-page {
	width: 100%;
	height: 730px;
	background-color: #FFFFFF;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 9;
}

.el-card.is-always-shadow,
.el-card.is-hover-shadow:focus,
.el-card.is-hover-shadow:hover {
	box-shadow: unset;
}

.el-card {
	border: 0;
}
.clear {
	clear: both;
}
.item-block {
	padding: 10px 20px 10px;
	margin: 10px 0;
	border-radius: 0;
	border: none;
	background: #ffffff;
	.block-text {
		color: $ns-text-color-black;
		padding: 7px 0;
	}
	.return-address {
		border-bottom: 1px solid #E4E7ED;
	}
	.address-wrap {
		padding: 20px 0px 0px;
		.title-text {
			font-weight: 700;
		}
		.block-text {
			padding: 0px;
		}
	}
	.refund-repair-wrap {
		padding: 10px 0px 0px;
		.title-text {
			font-weight: 700;
			padding: 0px 0px 10px;
		}
		.block-text {
			padding: 0px;
		}
	}
}
.order-submit {
	float: right;
	padding: 10px;
}
.goods-list {
	padding: 15px 0;
	table {
		width: 100%;
	}
	.goods-info-left {
		width: 60px;
		height: 60px;
		float: left;
		.goods-img {
			width: 60px;
			height: 60px;
		}
	}
	.goods-info-right {
		float: left;
		height: 60px;
		margin-left: 10px;
		color: $base-color;
		width: 80%;
		.goods-name {
			line-height: 20px;
			padding-top: 10px;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
			overflow: hidden;
		}
		.goods-spec {
			color: #999;
		}
	}
}
.pay-type-list {
	padding: 20px 0;
}
.pay-type-item {
	display: inline-block;
	border: 2px solid #eeeeee;
	padding: 5px 20px;
	margin-right: 20px;
	cursor: pointer;
}
.pay-type-item.active {
	border-color: $base-color;
}
.status_title h4 {
	margin: 10px 0 20px;
	border-bottom: 1px solid #eeeeee;
	padding-bottom: 10px;
}
.status-wrap {
	color: #999;
}
.media-left {
	float: left;
}
.media-right {
	float: right;
	cursor: pointer;
	i.rotate {
		transform: rotate(180deg);
		transition: 0.3s;
	}
}
.action-box {
	padding: 10px 0;
}
.action-way {
	float: left;
	color: #999;
}
.head .time {
	float: right;
	color: #999;
}
.record-item {
	margin-bottom: 10px;
}
.order-statistics {
	float: left;
	padding: 10px;
	width: 100%;
	// color: #999;
	.img-list {
		.file-box {
			width: 120px;
			height: 120px;
			margin-left: 10px;
			display: inline-block;
			&:first-child {
				margin-left: 0;
			}
			video {
				width: 100%;
				height: 100%;
			}
		}
		.el-image {
			width: 80px;
			margin-left: 10px;
			&:first-child {
				margin-left: 0;
			}
		}
	}
}

</style>
